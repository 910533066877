/*
 * @Desc:
 * @Author: JacksonZhou
 * @Date: 2021/11/28
 * @LastEditTime: 2021/12/12
 */
import { graphql } from "gatsby";
import * as React from "react";
import Comments from "../components/comments";
import Layout from "../components/layout";
import Seo from "../components/seo";

export default ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`;

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="welcome to my's blog" description="欢迎留言 ~" />
      <div>
        <img
          src="https://images.pexels.com/photos/12906178/pexels-photo-12906178.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load"
          alt="个人简介图片"
          style={{ width: "100%", height: 200 }}
        />
        <div style={{ marginTop: 16, lineHeight: "32px" }}>
          <div>Hi, welcome to my's blog</div>
          <div>I'm Lucas.</div>
          <div>email: jacksonzhou52017@gmail.com</div>
        </div>
      </div>
      <h5>留言板</h5>
      <Comments type="msgBoard" />
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
